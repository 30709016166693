@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');

*::-webkit-scrollbar{
  display: none;
}

*{
  -ms-overflow-style: none;
  scrollbar-width: none;
}

html {
  scroll-behavior: smooth;
}

body{
  padding: 0;
  margin: 0;
  font-family: 'Josefin Sans' !important;
  background-color: ivory;
  color: darkslategrey;
  margin-top: 12vh;
  font-size: 1.25rem;
}

h2{
  font-size: 2rem;
  text-align: center;
  margin-block-end: 0;
}

form{
  width: 50vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

form>input, textarea{
  margin: 2% 0;
  padding: 2%;
  border-radius: 5px;
  font-size: 1.5rem;
  font-weight: bold;
  resize: none;
  background-color: ivory;
  border: solid darkslategrey 5px;
  color: darkslategrey;
  transition: 0.5s;
}

input[type="submit"]{
  background-color: darkslategrey;
  color: ivory;
  border: solid ivory 5px;
}

#bioPhoto{
  margin: 0 auto;
  display: block;
  border-radius: 15px;
}

::placeholder {
  color: darkslategrey;
}

.content{
  padding: 2% 5%;
  width: 90%;
}

img.media{
  border-radius: 10px;
  display: inline-block;
  width: 31%;
  margin: 1%;
}

.mediaPhoto{
  overflow: hidden;
  width: 100%;
  padding: 0.5%
}

header{
  min-height: 100vh;
  transition: 1s;
  margin-top: -12vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-size: cover;
  background-attachment: fixed;
  flex-direction: column;
}

h1, header div{
  font-size: 3rem;
  color: ivory;
  padding: 1% 2%;
  margin: 0;
  text-align: right;
}

header span{
  font-size: 1.25rem !important;
}

header a, header span{
  font-size: 1rem;
  color: ivory;
  text-decoration: none;
}

header span{
  display: block;
  font-weight: bold;
}

.mediaLink{
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 3vh;
  padding: 2% 0;
}

.mediaLink * {
  height: 100%;
  transition: 0.2s;
}

.catalogueEntries{
  display: flex;
  flex-direction: column;
}

.catalogueEntries *{
  color: darkslategrey;
  text-decoration: none;
  font-size: 1.5rem;
}

.catalogueEntries div{
  margin: 2% 0;
}

.catalogueEntries a{
  display: block;
  width: fit-content;
}

.catalogueEntries span{
  background-color: darkslategrey;
  font-size: 1rem;
  padding: 0.5%;
  margin-right: 0.5%;
  border-radius: 5px;
  color: ivory;
  display: inline-block;
}

@media (pointer: fine) {
  input[type="submit"]:hover{
    margin: 2% 0;
    padding: 2%;
    border-radius: 5px;
    font-size: 1.5rem;
    font-weight: bold;
    resize: none;
    background-color: ivory;
    border: solid darkslategrey 5px;
    color: darkslategrey;
    transition: 0.5s;
  }

  .mediaLink *:hover {
    transform: scale(1.1);
  }

  .catalogueEntries a:hover{
    text-decoration: underline;
  }
}

@media (max-width: 1240px){
  img.media{
    width: 47.5%;
  }

  form{
    width: 90vw;
  }
}

@media (max-width: 940px){
  img.media{
    width: 97%;
  }
  header{
    justify-content: center;
  }
  h1{
    font-size: larger;
  }

  .mediaLink{
    flex-direction: column;
    height: auto;
  }

  .mediaLink * {
    margin: 5% 0;
    height: 1.5rem;
  }
}
nav{
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 12vh;
    width: 60vw;
    padding: 0 20%;
    position: fixed;
    top: 0;
    left: 0;
    font-size: 2rem;
    z-index: 999;
    background-color: rgba(47, 79, 79, 0.5);
    transition: 0.2s;
}

nav * {
    color: inherit;
    text-decoration: none;
    border-bottom: 5px rgba(0, 0, 0, 0) solid;
    transition: 0.2s;
    color: ivory;
}

.active{
    border-bottom: 5px ivory solid;
}

#goToTop{
    position: fixed;
    transform: rotateX(180deg);
    right: 2%;
    bottom: 2%;
    opacity: 0;
    text-decoration: none;
    color: ivory;
    background:rgba(47, 79, 79);
    padding: 2%;
    font-size: 2rem;
    border-radius: 15px;
    transition: 0.2s;
    width: 2rem;
    text-align: center;
    z-index: 998;
}

#goToTop.showGoToTop{
    opacity: 1;
}

#opener{
    display: none;
    background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}

@media (pointer: fine) {
    nav a:not(.active):hover{
        border-bottom: 5px ivory solid;
        transform: scale(1.25);
        padding-bottom: 0;
        transition: 0.2s;
    }    
}

@media (max-width: 1240px){
    nav{
        padding: 0;
        width: 100%;
    }
}

@media (max-width: 940px){
    nav.open{
        height: 100%;
    }

    nav.open *{
        display: block;
    }

    nav{
        flex-direction: column;
        width: 100%;
        padding: 0;
    }

    nav:not(.open) *:not(#opener){
        display: none;
    }

    #opener{
        display: inline;
    }
}